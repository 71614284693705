import React from 'react'
import { Helmet } from 'react-helmet';
import styles from './Federation.module.scss';
import {API_URL} from "../../utils/constants";

const Federation: React.FC = () => {
    return (
        <section className='section'>
            <Helmet>
                <title>Федерация | o-saratov</title>
                <meta name="description" content="Информация о Федерации спортивного ориентирования Саратовской области" />
                <meta name="keywords" content="o-saratov, федерация, СФСО, спортивное ориентирование в Саратове, ориентирование в Саратове, ориентирование Саратов" />
                <meta property="og:title" content="Федерация | o-saratov" />
                <meta property="og:description" content="Информация о Федерации спортивного ориентирования Саратовской области" />
                <meta property="og:url" content="http://o-saratov.ru/federation" />
                <meta property="og:site_name" content="=o-saratov" />

                <meta property="twitter:url" content="http://o-saratov.ru/federation" />
                <meta property="twitter:title" content="Федерация | o-saratov" />
                <meta property="twitter:description" content="Информация о Федерации спортивного ориентирования Саратовской области" />
            </Helmet>

            <h1 className={'title'}>Федерация</h1>
            <div className={styles.federationInfo}>
                <section className={styles.federationInfoBlock}>
                    <h3>Работа федерации</h3>
                    <ul>
                        <li><a href={"https://disk.yandex.ru/i/GGXWq7ipEAxK4w"} target="_blank" rel="noreferrer">Устав федерации</a></li>
                        <li><a href={API_URL + "/media/uploads/spisok_pravlenia.docx"} target="_blank" rel="noreferrer">Члены правления СФСО</a></li>
                        <li><a href={API_URL + "/media/uploads/Spiski_sbornoy_2022.doc"} target="_blank" rel="noreferrer">Списки кандидатов в сборную области</a></li>
                    </ul>
                </section>
                <section className={styles.federationInfoBlock}>
                    <h3>Координаты федерации</h3>
                    <ul>
                        <li>Юридический адрес: 410033, г. Саратов, ул. Гвардейская, 13</li>
                        <li>Физический адрес: г. Саратов, ул. Шелковичная 178Б </li>
                        <li>Телефон: <a href="tel:+79172151708">+7 (917) 215 17 08</a></li>
                        <li>Почта: <a href="mailto:o-saratov@yandex.ru">o-saratov@yandex.ru</a></li>
                    </ul>
                </section>
                <section className={styles.federationInfoBlock}>
                    <h3>Банковские реквизиты</h3>
                    <ul>
                        <li>АКБ "ГазНефтьБанк" БИК 046322801 ИНН 6453050924</li>
                        <li>Корр.счёт № 30101810700000000801</li>
                        <li>Р\с № 40703810400000000019</li>
                    </ul>
                </section>
            </div>

        </section>
    )
}

export default Federation
