import React from 'react';

const Error = () => {
    return (
        <div className={'app'}>
            <h2 className={'title'}>Ошибка!</h2>
            <p>
                Такой страницы не существует!
            </p>
        </div>
    );
};

export default Error;
