import {useParams} from 'react-router-dom';
import React, {useEffect, useState, ReactElement} from "react";
import axios from 'axios';
import styles from "./index.module.scss";
import ReactHtmlParser from "react-html-parser";
import ContentLoader from "react-content-loader";
import {Helmet} from "react-helmet";

import moment from 'moment';
import 'moment/locale/ru';
import {IPost} from '../../../utils/types';
import {API_URL} from "../../../utils/constants";

moment.locale('ru');

const POST_CONTENT_LOADER: ReactElement = (<ContentLoader
    speed={2}
    width="100%"
    height="300"
    viewBox="0 0 960 300"
    backgroundColor="#ffffffbb"
    foregroundColor="#ecebebbb"
    className={styles.contentLoader}
>
    <rect
        x="0"
        y="30"
        rx="15"
        ry="15"
        width="100%"
        height="300"
    />
</ContentLoader>);

const NewsDetail: React.FC = () => {
    const {postId} = useParams<Record<string, string | undefined>>();

    const [isPostLoading, setIsPostLoading] = useState<boolean>(true);
    const [post, setPost] = useState<IPost>({
        id: -1,
        date: "",
        header: "",
        text: "",
    });

    useEffect(() => {
        try {
            setIsPostLoading(true);
            axios
                .get(`${API_URL}/news/${postId}`)
                .then((response) => {
                    const data: IPost = response.data;
                    setPost(data);
                    setIsPostLoading(false);
                })
                .catch((error) => console.error(error));
        } catch (error) {
            console.error(error);
            setIsPostLoading(false);
        }
    }, [postId]);

    return (
        isPostLoading ? (POST_CONTENT_LOADER) : (
            <>
                <Helmet>
                    <meta name="description"
                          content={`o-saratov | Новость ${post.header}`}/>
                    <meta name="keywords"
                          content="o-saratov, новости, статьи про ориентирование, статьи, спортивное ориентирование в Саратове, ориентирование в Саратове, ориентирование Саратов"/>
                    <title>{post.header} | o-saratov</title>
                    <meta property="og:type" content="post"/>
                    <meta property="og:title" content="o-saratov | Новость"/>
                    <meta property="og:description" content={post.header}/>
                    <meta property="og:site_name" content="o-saratov"/>
                    <meta property="og:url" content={`${window.location.href}/`}/>
                </Helmet>

                <h2 className="title">Новость</h2>

                <article className={`${styles.post}`}>
                    <time dateTime={post.date} title={moment(post.date).format("LLLL")}>
                        {moment(post.date).format("LL")}
                    </time>
                    <h2 className={styles.post__title}>
                        {post.header}
                    </h2>
                    <div className="parsed">
                        {ReactHtmlParser(post.text)}
                    </div>
                </article>
            </>
        )
    );
}

export default NewsDetail;

