import styles from "./Maps.module.scss";
import {LazyLoadImage} from "react-lazy-load-image-component";
import MapField from "./MapField";
import React from "react";
import {IMap} from "../../utils/types";

interface MapProps {
    item: IMap;
}

const Map: React.FC<MapProps> = ({item}) => {
    return (
        <div className={styles.map}>
            <a href={require(`../../img/${item['imgName']}`)} target="_blank" rel="noreferrer">
                <LazyLoadImage
                    src={require(`../../img/${item['imgName']}`)}
                    effect="blur"
                    width={"100%"}
                    placeholder={<span>Загрузка изображения</span>}
                />
            </a>
            <div className={styles.map__content}>
                <p className={styles.name}>{item['name']}</p>
                <MapField label="Масштаб" text={item['scale']}/>
                <MapField label="Сечение рельефа" text={item['relief']}/>
                <MapField label="Год" text={item['year']}/>
                <MapField label="Вычертил" text={item['drew']}/>
                <MapField label="Составители" text={item['authors']}/>
                <MapField label="Инспектор" text={item['inspector']}/>
                <MapField label="Дополнительное описание" text={item['description']}/>
                <MapField label="Подготовлена" text={item['date']}/>
            </div>

        </div>
    )
}

export default Map;
