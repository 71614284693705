import React, {useEffect, useState, ReactElement} from "react";
import axios from 'axios';
import {IArticle} from "../../utils/types";
import styles from "./Article.module.scss";
import ReactHtmlParser from "react-html-parser";
import ContentLoader from "react-content-loader";
import {Helmet} from "react-helmet";

import moment from 'moment';
import 'moment/locale/ru';
import BackButton from "../BackButton";
import {useParams} from "react-router-dom";
import {API_URL} from "../../utils/constants";

moment.locale('ru');


const ARTICLE_CONTENT_LOADER: ReactElement = (<ContentLoader
    speed={2}
    width="100%"
    height="300"
    viewBox="0 0 960 300"
    backgroundColor="#ffffffbb"
    foregroundColor="#ecebebbb"
    className={styles.contentLoader}
>
    <rect
        x="0"
        y="30"
        rx="15"
        ry="15"
        width="100%"
        height="300"
    />
</ContentLoader>);


const ArticleDetail: React.FC = () => {
    const {articleId} = useParams<Record<string, string | undefined>>();

    const [isArticleLoading, setIsArticleLoading] = useState<boolean>(true);
    const [article, setArticle] = useState<IArticle>({
        id: -1,
        date: "",
        header: "",
        text: "",
        cover: "",
        source: "",
        author: "",
    });

    useEffect(() => {
        try {
            setIsArticleLoading(true);
            axios
                .get(API_URL + "/articles/" + articleId)
                .then((response) => {
                    const data: IArticle = response.data;
                    setArticle(data);
                    setIsArticleLoading(false);
                })
                .catch((error) => console.log(error));
        } catch (error) {
            console.log(error);
            setIsArticleLoading(false);
        }
    }, [articleId]);

    return (
        isArticleLoading ? (ARTICLE_CONTENT_LOADER) : (
            <>
                <Helmet>
                    <meta name="description"
                          content={`o-saratov | Статья ${article.header}`}/>
                    <meta name="keywords"
                          content="o-saratov, статьи про ориентирование, статьи, спортивное ориентирование в Саратове, ориентирование в Саратове, ориентирование Саратов"/>
                    <title>{article.header} | o-saratov</title>
                    <meta property="og:type" content="post"/>
                    <meta property="og:title" content="o-saratov | Статья"/>
                    <meta property="og:description" content={article.header}/>
                    <meta property="og:site_name" content="o-saratov"/>
                    <meta property="og:url" content={`${window.location.href}/`}/>
                </Helmet>

                <h2 className="title">Статья</h2>

                <article className={styles.article}>
                    <time dateTime={article.date} title={moment(article.date).format("LLLL")}>
                        {moment(article.date).format("LL")}
                    </time>
                    <h2 className={styles.article__title}>
                        {article.header}
                    </h2>
                    <div className="parsed">
                        {ReactHtmlParser(article.text)}
                    </div>
                    {article.source && (
                        <span className={styles.source}>Источник: <mark>{article.source}</mark>
                        </span>
                    )}
                    {article.author && (
                        <span className={styles.author}>Автор: <mark>{article.author}</mark>
                        </span>
                    )}
                </article>
            </>
        )

    );
};
export default ArticleDetail;
