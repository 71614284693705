import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import styles from "./BlurEffectImage.module.scss";

interface IBlurEffectImageProps {
    src: string,
    alt: string
}

const BlurEffectImage: React.FC<IBlurEffectImageProps> = ({ src, alt }) => {
    return (
        <div className={styles.blurEffect}>
            <LazyLoadImage
                className={styles.image}
                alt={alt}
                src={src}
                key={src}
                effect="blur"
                placeholder={<span>Загрузка изображения</span>}
            />
            <img src={src} alt={alt} className={styles.effect} />
        </div>
    )
}

export default BlurEffectImage