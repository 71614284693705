import React from 'react'

interface IMapFieldProps {
    text: string | undefined,
    label: string
}

const MapField: React.FC<IMapFieldProps> = ({ label, text }) => {
    return (
        text ? (<span>{label}: {text}</span>
        ) : (<></>)

    )
}

export default MapField
