import styles from "./Navbar.module.scss";
import { NavLink } from "react-router-dom";

interface INavbarItemProps {
    toPath: string,
    text: string
}

const NavbarItem: React.FC<INavbarItemProps> = ({ toPath, text, }) => {
    return (
        <NavLink
            to={toPath}
            className={({ isActive }) => (isActive ? `${styles.item} ${styles.active}` : styles.item)}
        >
            <span className="link">{text}</span>
        </NavLink>
    );
};

export default NavbarItem;
