import React from 'react'
import styles from "./Maps.module.scss";
import BackButton from "../BackButton";
import {NavLink, useParams} from "react-router-dom";
import {MAPS} from "../../utils/constants";
import {Helmet} from "react-helmet";


const MapsRegions: React.FC = () => {
    const {regionIndex} = useParams<Record<string, string | undefined>>();
    return (
        <div className={styles.maps}>
            <Helmet>
                <meta name="description"
                      content={`Спортивные карты Саратовской области: ${MAPS[parseInt(regionIndex || "")]['region']}`}/>
                <meta name="keywords"
                      content="o-saratov, карты, спортивные карты, спортивное ориентирование в Саратове, ориентирование в Саратове, ориентирование Саратов"/>
                <title>Карты {MAPS[parseInt(regionIndex || "")]['region']} | o-saratov</title>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={`Карты ${MAPS[parseInt(regionIndex || "")]['region']} | o-saratov`}/>
                <meta property="og:description"
                      content={`Спортивные карты Саратовской области: ${MAPS[parseInt(regionIndex || "")]['region']}`}/>
                <meta property="og:site_name" content="o-saratov"/>
            </Helmet>

            <h2 className={styles.maps__title}>
                <div className={styles.mapsBackButton}>
                    <BackButton/>
                </div>
                {MAPS[parseInt(regionIndex || "")]['region']}
            </h2>
            <ul className={`${styles.maps__region} ${styles.maps__places}`}>
                {MAPS[parseInt(regionIndex || "")]['places'].map((item, index) => (
                    <li key={item['name']} className={styles.maps__place}>
                        <NavLink to={`/maps/${regionIndex}/${index}`}>{item['name']}</NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default MapsRegions
