import {IMaps} from "./types";

export const API_URL: string = process.env.REACT_APP_API_URL || "";

// FIXME
export const MAPS: IMaps[] = [
    {
        region: "г.Саратов и окрестности",
        places: [
            {
                name: "Лесопарк \"Кумысная поляна\"",
                maps: [
                    {
                        name: "1-4 дачные",
                        imgName: "1-4_дачные_1991.jpg",
                        description: "Класс: В.",
                        scale: "1:10000",
                        relief: "5м",
                        year: "1991",
                        drew: "Деев С.",
                        authors: "Ачкасов Г., Деев С., Постнов Д., Жбанчик В., Борисов В., Анциферов А",
                        inspector: "Солдаткин С",
                        date: "в апреле 1991 года"
                    },
                    {
                        name: "3-9 дачные",
                        imgName: "3-9_дачные_1999.jpg",
                        scale: "1:10000",
                        relief: "5м",
                        year: "1999",
                        authors: "Аверьянов А., Аверьянов С., Сафронов В.А., Афонина С.А., Хохлов К., Нефёдов И",
                        date: "в апреле 1999 года",
                        description: "Компьютерная графика: Аверьянов А., Аверьянов С. При подготвке использовались материалы: Деева С. Напечатана на личные средства Кузнецова Алексея."
                    },
                    {
                        name: "10-я дачная",
                        imgName: "2002_svetoch_big.jpg",
                        scale: "1:10000",
                        relief: "5м",
                        year: "2002",
                        authors: "Деев С., Семёнов А., Солдаткин С",
                        date: "в октябре 2001 - апреле 2002 года специально для соревнований на \"Кубок СВЕТОЧ\"",
                    },
                    {
                        name: "Малая Кумысная поляна",
                        imgName: "2001_svetoch_big.jpg",
                        scale: "1:10000",
                        relief: "2,5м",
                        year: "2001",
                        authors: "Аверьянов А.В., Шаруев В.Н.",
                        date: "в октябре-ноябре 2000 года специально для соревнований на \"Кубок СВЕТОЧ\"",
                    },
                    {
                        name: "Малиновый родник",
                        imgName: "1999_malin_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1999",
                        authors: "Деев С., Семёнов А., Солдаткин С",
                        date: "в сентябре 1999 года",
                        description: "Использовны материалы авторов карт: Ачкасова Г., Жбанчика В., Постнова Д."
                    },
                    {
                        name: "п.Сокол",
                        imgName: "1992_socol_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1992",
                        drew: "Жбанчик В."
                    },
                    {
                        name: "Цементный столбик",
                        imgName: "1999_Svetoch_big.jpg",
                        scale: "1:10000",
                        relief: "5м",
                        year: "1999",
                        authors: "Аверьянов А.В.",
                        date: "в июне-августе 1999 года специально для соревнований на \"Кубок СВЕТОЧ\"",
                    },
                    {
                        name: "Корольков сад",
                        imgName: "KOROLIKOV_SAD_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        description: "Спорткарта №18",
                    },
                ]
            }
        ]
    },
    {
        region: "Саратовская область",
        places: [
            {
                name: "Базарный Карабулак",
                maps: [
                    {
                        name: "п/л Ласточка",
                        imgName: "1990_lastochka_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        date: "в 1982-м году. Обновлена в 1990-м",
                        drew: "Ачкасов Г.А",
                        description: "Спорткарта №22"
                    },
                    {
                        name: "Красные пески",
                        imgName: "KRASNYE_PESKI_85_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        date: "в 1985-м году",
                        authors: "Грачёв В., Деев С., Нефёдов И., Солдаткин С., Шаруев Н",
                        description: "Издательские оригиналы выполнил: Деев С."
                    },
                    {
                        name: "Алексеевка",
                        imgName: "KRASNYE_PESKI_85_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        date: "в 1983-м году",
                        description: "Спорткарта №25"
                    },
                ]
            },
            {
                name: "Балаково",
                maps: [
                    {
                        name: "\"Синяя птица\" (спорткарта №18)",
                        imgName: "1991_balakovo_blue_bird_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        drew: "Жбанчик В.И.",
                        year: "1991"
                    },
                    {
                        name: "Плотина ГЭС",
                        imgName: "1980_balakovo_ges_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        authors: "не известны",
                        year: "1980"
                    },
                ]
            },
            {
                name: "Балашов",
                maps: [
                    {
                        name: "Балашов",
                        imgName: "1980_balashov.jpg",
                        scale: "1:10000",
                        relief: "3м",
                        authors: "Жбанчик В., Андреев А.",
                        description: "Класс А"
                    },

                ]
            },
            {
                name: "Буркин Буерак",
                maps: [
                    {
                        name: "Буркин Буерак (ост.платформа 28)",
                        imgName: "2000_byrkin_big.jpg",
                        scale: "1:10000",
                        relief: "5м",
                        year: "2000",
                        authors: "Деев С., Семёнов А., Солдаткин С.",
                        description: "Использованы материалы авторов карт: Сафронова В., Ачкасова Г., Жбанчика В. и др. Последняя редакция карты: июнь - август 2000 года."
                    },

                ]
            },
            {
                name: "Воскресенское",
                maps: [
                    {
                        name: "Воскресенское",
                        imgName: "VOSKRESENSKOE_91_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1991",
                        authors: "Ачкасов Г., Деев С.",
                        drew: "Деев С.",
                        inspector: "Солдаткин С.",
                        description: "Класс: В."
                    },
                ]
            },
            {
                name: "Ивановский",
                maps: [
                    {
                        name: "Ивановский",
                        imgName: "IVANOVSKIY_98_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1998",
                        authors: "Грачёв В., Деев С., Солдаткин С. (1996)",
                        description: "Первая редакция карты: Аблязов Камиль (1976). Последние изменения на местности внесены в новую редакцию карты Семеновым А. "
                    },
                ]
            },
            {
                name: "Идолга",
                maps: [
                    {
                        name: "Идолга",
                        imgName: "1991_idolga_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1991",
                        authors: "СК СГУ",
                        drew: "Деев С.",
                        description: "Руководитель работ: Постнов Д. Организация печати: КОТ \"Ориент\""
                    },
                ]
            },
            {
                name: "Колотов Буерак",
                maps: [
                    {
                        name: "Колотов Буерак",
                        imgName: "1996_colotov_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1996",
                        authors: "Грачёв В., Деев С., Солдаткин С. (1996)",
                        description: "Первая редакция карты: Аблязов К. (1977)"
                    },
                ]
            },
            {
                name: "Красный Октябрь",
                maps: [
                    {
                        name: "Красный Октябрь (ближний)",
                        imgName: "1980_red_october_near_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1980",
                        authors: "Антонов А., Кузнецов В., Сафронов В.",
                    },
                    {
                        name: "Красный Октябрь (средний)",
                        imgName: "1980_red_october_mid_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1980",
                        authors: "Картамышев В.М.",
                    },
                ]
            },
            {
                name: "Красный Текстильщик",
                maps: [
                    {
                        name: "Красный Текстильщик",
                        imgName: "2001_red_textile_worker_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "2001",
                        authors: "Грачёв В., Деев С., Солдаткин С. (1996)",
                        description: "Первая редакция карты: Михайлов А. (1980) Последняя редакция карты: Деев С., Семёнов А. (2001)"
                    },
                    {
                        name: "Красный Текстильщик",
                        imgName: "2001_red_textile_worker_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1996",
                        authors: "Грачёв В., Деев С., Солдаткин С. (1996)",
                        description: "Первая редакция карты: Михайлов А. (1980) Последняя редакция карты: Деев С., Семёнов А. (2001)"
                    },

                ]
            },
            {
                name: "Луганское",
                maps: [
                    {
                        name: "Балка Дьяконова",
                        imgName: "2001_beem_diyakonova_big.jpg",
                        scale: "1:10000",
                        relief: "5м",
                        year: "2001",
                        authors: '1979-80 гг.. - Первое освоение района. Полевые работы: Ачкасов Г., Шаруев Н. Цветная карта выполнена Ачкасовым Г. 1996 г. - Новая редакция района.Спорткарты: "Луганское (13 кв.км), "Мордовое" (13,2 кв.км) созданы бригадой составителей карт: Грачёвым В., Деевым С., Солдаткиным С. (бр.) по материалам аэрофотосъёмки. 1988 г. - Последующая редакция района спорткарта "Балка Толлегина".',
                        description: `Редакторы: Грачёв В. (бр.), Деев С., Солдаткин С.
2001 г. - Последняя редакция района "Балка Дьяконова". Полевые работы и компьютерная графика май-август 2001 г. Деев С., Семёнов А.
Инспекция района: Сафронов В.А., Аристов В.`
                    },

                    {
                        name: "Балка Толлегина",
                        imgName: "1998_beem_tollegina_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1998",
                        authors: `1979-80 гг.. - Первое освоение района. Полевые работы: Ачкасов Г., Шаруев Н. Цветная карта выполнена Ачкасовым Г.
1996 г. - Новая редакция района. Спорткарты: "Луганское (13 кв.км), "Мордовое" (13,2 кв.км) созданы бригадой составителей карт: Грачёвым В., Деевым С., Солдаткиным С. (бр.) по материалам аэрофотосъёмки.
1988 г. - Последующая редакция района спорткарта "Балка Толлегина".`,
                        description: `Редакторы: Грачёв В. (бр.), Деев С., Солдаткин С.
2001 г. - Последняя редакция района "Балка Дьяконова". Полевые работы и компьютерная графика май-август 2001 г. Деев С., Семёнов А.`
                    },

                    {
                        name: "Мордовое",
                        imgName: "MORDOVOE_95_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1995",
                        authors: `Грачёв В., Деев С., Солдаткин С.`,
                        drew: "Деев С.",
                        inspector: "Сафронов В.В.",
                        description: `Площадь района: 13,2 кв.км.`
                    },

                    {
                        name: "Луганское",
                        imgName: "LUGANSKOE_94_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1994",
                        authors: `Грачёв В., Деев С., Солдаткин С.`,
                        drew: "Деев С.",
                        inspector: "Сафронов В.В.",
                        description: `Площадь района: 10,2 кв.км.`
                    },


                ]
            },

            {
                name: "Озёрки",
                maps: [
                    {
                        name: "Озёрки",
                        imgName: "1987_ozerki_big.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1987",
                        authors: "Жбанчик В.Н.",
                    },
                ]
            },

            {
                name: "Терса",
                maps: [
                    {
                        name: "Терса (~15 км от Вольска)",
                        imgName: "1980_tersa_big.jpg",
                        scale: "1:15000",
                        relief: "3м",
                        year: "1980",
                        authors: "неизвестны",
                    },
                ]
            },
            {
                name: "Урицкое",
                maps: [
                    {
                        name: "Атаевка",
                        imgName: "2000_ataevka_big.jpg",
                        scale: "1:10000",
                        relief: "2,5м",
                        description: `Первая редакция карты: Картамышев В. (1978)
Последняя редакция карты: Деев С., Семёнов А., Солдаткин С. (2000)`
                    },
                    {
                        name: "Лес Иняки",
                        imgName: "1997_urickoe_inyaki_big.jpg",
                        scale: "1:10000",
                        relief: "2,5м",
                        year: "1997",
                        authors: "Грачёв В., Деев С., Солдаткин С."
                    },
                    {
                        name: "	Лес Иняки-1",
                        imgName: "1997_urickoe_inyaki_1_big.jpg",
                        scale: "1:10000",
                        relief: "2,5м",
                        year: "1997",
                        authors: "Солдаткин С., Грачёв В., Деев С."
                    },
                ]
            },

            {
                name: "Хвалынск",
                maps: [
                    {
                        name: "Хвалынск (Мелзавод, юг)",
                        imgName: "1991_hvalynsk_big.jpg",
                        scale: "1:15000",
                        year: "1991",
                        relief: "5м",
                        authors: ` Ачкасов Г., Солдаткин С., Деев С., Жбанчик В., Борисов В., Derebrant P-O.`
                    },
                    {
                        name: "Хвалынск (Мелзавод, север. Споркарта №17)",
                        imgName: "1980_hvalynsk_n17_big.jpg",
                        scale: "1:15000",
                        year: "1980",
                        relief: "5м",
                        authors: `неизвестны`
                    },

                ]
            },
            {
                name: "Широкий Буерак",
                maps: [
                    {
                        name: "Широкий Буерак",
                        imgName: "1999_shiroky_big.jpg",
                        scale: "1:10000",
                        year: "1999",
                        relief: "5м",
                        description: `Последняя редакция: Деев С., Семёнов А., Солдаткин С.
Использовались материалы карт: Ачкасова Г., Шаруева Н.`
                    },
                    {
                        name: "Широкий Буерак (Спорткарта - 2)",
                        imgName: "SHIROKIY_BUERAK_88_BIG.jpg",
                        scale: "1:15000",
                        year: "1988",
                        relief: "5м",
                        authors: `Ачкасов Г., Деев С., Борисов В.`,
                        drew: "Деев С.",
                        inspector: "СВК Сафронов В.",
                        description: "Класс: В."
                    },

                ]
            },

        ]
    },
    {
        region: "Разное",
        places: [
            {
                name: "Разное",
                maps: [
                    {
                        name: "Кислово",
                        imgName: "KISLOVO_91_BIG.jpg",
                        scale: "1:10000",
                        relief: "2м",
                        year: "1991",
                        description: "Карта составлена клубом \"ОРИЕНТ\" г.Саратов"
                    },
                    {
                        name: "Сергеевский",
                        imgName: "SERGEEVSKIY_1_BIG.jpg",
                        scale: "1:20000",
                        relief: "5м",
                        description: "Спорткарта №7"
                    },
                    {
                        name: "Дом отдыха \"Отрадное\"",
                        imgName: "UNKNOWN_1_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1986",
                        drew: "Борисов Володя",
                        authors: "Бахмуров С., Борисов В., Гурьянов Е., Яксанов С.",
                        description: "Красноармейский район."
                    },
                    {
                        name: "Ново-Бурасский район, севернее 10км с.Тепловка, а затем на запад ~8км",
                        imgName: "UNKNOWN_2_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1981",
                        authors: "Картамышев В. (один из трех гигантов-спартаковцев восмидесятых - в 1983 году он был третьим на чемпионате СССР).",
                    },
                    {
                        name: "с.Тепловка (севернее Саратова ~40км)",
                        imgName: "UNKNOWN_3_BIG.jpg",
                        scale: "1:15000",
                        relief: "5м",
                        year: "1980",
                        authors: "Картамышев В.",
                    },

                ]
            },
        ]
    }
];
