import React from 'react';
import {Outlet} from 'react-router-dom';
import Navbar from "../components/Navbar/Navbar";

const Root = () => {

    return (
        <div className="app">
            <h1 className="visually-hidden">Официальный сайт по спортивному ориентированию в Саратовской области</h1>
            <Navbar/>
            <main className="main">
                <Outlet/>
            </main>
        </div>
    );
};

export default Root;
