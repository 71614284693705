import axios from "axios";
import {Helmet} from "react-helmet";
import {ReactElement, useEffect, useState, useCallback} from "react";
import {IArticle} from "../../utils/types";
import styles from "./Articles.module.scss";
import Article from "./Article";
import ContentLoader from "react-content-loader";
import Search from "../Search";
import moment from 'moment';
import 'moment/locale/ru';
import {API_URL} from "../../utils/constants";

moment.locale('ru');

const filterArticles = (articles: IArticle[], searchValue: string): IArticle[] => {
    return articles
        .filter(
            (item) =>
                item.header
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                moment(item.date).format("LL")
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
        )
}


const ArticlesList: React.FC = () => {
    const CONTENT_LOADER: ReactElement = (<ContentLoader
        speed={2}
        width="100%"
        // height="200"
        viewBox="0 0 960 500"
        backgroundColor="#ffffffbb"
        foregroundColor="#ecebebbb"
        className={styles.contentLoader}
    >
        <rect
            x="0"
            y="30"
            rx="15"
            ry="15"
            width="100%"
            height="500"
        />
    </ContentLoader>);

    const ARTICLES_CONTENT_LOADERS: ReactElement[] = Array(3).fill(CONTENT_LOADER);

    const [articles, setArticles] = useState<IArticle[]>([]);
    const [isArticlesLoading, setIsArticlesLoading] = useState<boolean>(true);
    const [searchValue, setSearchValue] = useState<string>("");
    const [nextPageLink, setNextPageLink] = useState<string>("");
    const [prevPageLink, setPrevPageLink] = useState<string>("");
    const [currentPageLink, setCurrentPageLink] = useState<string>(API_URL + "/articles/");

    const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const onClickNextPage = () => {
        setCurrentPageLink(nextPageLink);
    }
    const onClickPrevPage = () => {
        setCurrentPageLink(prevPageLink);
    }

    const memoizedFetchArticles = useCallback(
        () => {
            try {
                setIsArticlesLoading(true);
                axios
                    .get(currentPageLink)
                    .then((response) => {
                        const data: IArticle[] = response.data['results'];
                        // fixme
                        let prevLink = response.data['previous'];
                        let nextLink = response.data['next'];
                        if (API_URL.startsWith('https://')) {
                            if (prevLink) {
                                prevLink = prevLink.replace('http://', 'https://');
                            }
                            if (nextLink) {
                                nextLink = nextLink.replace('http://', 'https://');
                            }
                        }
                        setPrevPageLink(prevLink);
                        setNextPageLink(nextLink);
                        setArticles(data);
                        setIsArticlesLoading(false);
                    })
                    .catch((error) => console.log(error));
            } catch (error) {
                console.log(error);
                setIsArticlesLoading(false);
            }
        },
        [currentPageLink],
    );

    useEffect(() => {
        memoizedFetchArticles();
    }, [memoizedFetchArticles]);

    return (
        <section className="section">
            <Helmet>
                <meta name="description"
                      content="Страница статей официального сайта по спортивному ориентированию в Саратовской области"/>
                <meta name="keywords"
                      content="o-saratov, новости, статьи про ориентирование, статьи, спортивное ориентирование в Саратове, ориентирование в Саратове, ориентирование Саратов"/>
                <title>Статьи | o-saratov</title>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Статьи | o-saratov"/>
                <meta property="og:description"
                      content="Страница статей официального сайта по спортивному ориентированию в Саратовской области"/>
                <meta property="og:site_name" content="o-saratov"/>
            </Helmet>
            <h1 className={'title'}>Статьи</h1>
            <Search
                searchValue={searchValue}
                onSearchInput={onSearchInput}
            />
            {isArticlesLoading ? (
                <>
                    {ARTICLES_CONTENT_LOADERS}
                </>
            ) : (
                <div className={styles.articles}>
                    {articles.length > 0 && !isArticlesLoading ? (
                        filterArticles(articles, searchValue).length > 0 ? (
                            [
                                ...filterArticles(articles, searchValue)
                                    .map((item) => <Article key={item.id} {...item} />),
                                (
                                    <div className="paginationButtons">
                                        <button onClick={onClickNextPage} disabled={!nextPageLink}>
                                            Предыдущая
                                        </button>
                                        <button onClick={onClickPrevPage} disabled={!prevPageLink}>
                                            Следующая
                                        </button>
                                    </div>
                                )]
                        ) : (
                            <p className={styles.articles__errorMessage}>Ничего не найдено 😔</p>
                        )
                    ) : (
                        <p className={styles.articles__errorMessage}>Ошибка! Сервер недоступен 😔</p>
                    )}
                </div>
            )
            }
        </section>
    );
};
export default ArticlesList;
