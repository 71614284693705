import React from "react"
import styles from "./Maps.module.scss";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import {MAPS} from "../../utils/constants";

const Maps: React.FC = () => {
    return (
        <section className="section">
            <Helmet>
                <meta name="description"
                      content="Спортивные карты Саратовской области"/>
                <meta name="keywords"
                      content="o-saratov, карты, спортивные карты, спортивное ориентирование в Саратове, ориентирование в Саратове, ориентирование Саратов"/>
                <title>Карты | o-saratov</title>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Карты | o-saratov"/>
                <meta property="og:description"
                      content="Спортивные карты Саратовской области"/>
                <meta property="og:site_name" content="o-saratov"/>
            </Helmet>

            <h2 className={'title'}>Карты</h2>

            <div className={styles.maps}>
                {
                    MAPS.map((item, index) => (
                        <div className={`${styles.maps__regions}`}>
                            <NavLink to={'/maps/' + index}>
                                <h3 className={styles.maps__regionName}>{item['region']}</h3>
                            </NavLink>
                            <ul className={styles.maps__places}>
                                {MAPS[index]['places'].map((item, placeIndex) => (
                                    <li key={item['name']} className={styles.maps__place}>
                                        <NavLink to={`/maps/${index}/${placeIndex}`}>{item['name']}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))
                }
                <section className={styles.maps__additional}>
                    <p className={styles.maps__subtitle}>Внимание!</p>
                    <p>
                        По вопросам приобретения спортивных карты обращайтесь в СФСО.
                        Спорткарты Саратовской области были предоставлены Деевым Сергеем.
                        Представленный список карт далеко не полный и если у Вас имеются неопубликованные карты,
                        присылайте их по адресу <a href="mailto:o-saratov@yandex.ru">o-saratov@yandex.ru</a>
                    </p>
                </section>
            </div>
        </section>
    )
}

export default Maps;
