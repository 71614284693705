import React from 'react'
import styles from './Authors.module.scss';

const Authors: React.FC = () => {
    return (
        <section className='section'>
            <h1 className={'title'}>Авторы</h1>
            <div className={styles.authors}>
                <p><a href="https://vk.com/yakser" target="_blank" rel="noreferrer">Сергей Яксанов мл.</a> - разработка, администрирование, сопровождение</p>
                <p><a href="https://vk.com/id186896288" target="_blank" rel="noreferrer">Сергей Яксанов ст.</a> - ведение новостей</p>
            </div>
        </section>
    )
}

export default Authors
