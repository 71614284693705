import axios from "axios";
import {ReactElement, useEffect, useState, useCallback} from "react";
import {IPost} from "../../utils/types";
import styles from "./News.module.scss";
import Post from "./Post";
import ContentLoader from "react-content-loader";
import Search from "../Search";
import moment from 'moment';
import 'moment/locale/ru';
import {Helmet} from "react-helmet";
import {API_URL} from "../../utils/constants";

moment.locale('ru');


const News: React.FC = () => {
    const CONTENT_LOADER: ReactElement = (<ContentLoader
        speed={2}
        width="100%"
        // height="500"
        viewBox="0 0 960 500"
        backgroundColor="#ffffffbb"
        foregroundColor="#ecebebbb"
        className={styles.contentLoader}
    >
        <rect
            x="0"
            y="30"
            rx="15"
            ry="15"
            width="100%"
            height="500"
        />
    </ContentLoader>);

    const NEWS_CONTENT_LOADERS: ReactElement[] = Array(3).fill(CONTENT_LOADER);

    const [news, setNews] = useState<IPost[]>([]);
    const [filteredNews, setFilteredNews] = useState<IPost[]>([]);
    const [isNewsLoading, setIsNewsLoading] = useState<boolean>(true);
    const [searchValue, setSearchValue] = useState<string>("");
    const [nextPageLink, setNextPageLink] = useState<string>("");
    const [prevPageLink, setPrevPageLink] = useState<string>("");
    const [currentPageLink, setCurrentPageLink] = useState<string>(process.env.REACT_APP_API_URL + "/news/");

    const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        filterNews(news, event.target.value);
    };

    const onClickNextPage = () => {
        setCurrentPageLink(nextPageLink);
    }
    const onClickPrevPage = () => {
        setCurrentPageLink(prevPageLink);
    }

    const filterNews = (news: IPost[], searchValue: string) => {
        setFilteredNews(news
            .filter(
                (item) =>
                    item.header
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                    moment(item.date).format("LL")
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
            ));
    }


    const memoizedFetchNews = useCallback(
        () => {
            try {
                setIsNewsLoading(true);
                axios
                    .get(currentPageLink)
                    .then((response) => {
                        const data: IPost[] = response.data['results'];
                        // fixme
                        let prevLink = response.data['previous'];
                        let nextLink = response.data['next'];
                        if (API_URL.startsWith('https://')) {
                            if (prevLink) {
                                prevLink = prevLink.replace('http://', 'https://');
                            }
                            if (nextLink) {
                                nextLink = nextLink.replace('http://', 'https://');
                            }
                        }
                        setPrevPageLink(prevLink);
                        setNextPageLink(nextLink);
                        setNews(data);
                        filterNews(data, "");
                        setIsNewsLoading(false);
                    })
                    .catch((error) => console.log(error));
            } catch (error) {
                console.log(error);
                setIsNewsLoading(false);
            }
        },
        [currentPageLink],
    );

    useEffect(() => {
        memoizedFetchNews();
    }, [memoizedFetchNews]);

    return (
        <section className="section">
            <Helmet>
                <meta name="description"
                      content="Страница новостей официального сайта по спортивному ориентированию в Саратовской области"/>
                <meta name="keywords"
                      content="o-saratov, новости, статьи про ориентирование, статьи, спортивное ориентирование в Саратове, ориентирование в Саратове, ориентирование Саратов"/>
                <title>Новости | o-saratov</title>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Новости | o-saratov"/>
                <meta property="og:description"
                      content="Страница новостей официального сайта по спортивному ориентированию в Саратовской области"/>
                <meta property="og:site_name" content="o-saratov"/>
            </Helmet>

            <h2 className={'title'}>Новости</h2>
            <Search
                searchValue={searchValue}
                onSearchInput={onSearchInput}
            />
            {isNewsLoading ? (
                <>
                    {NEWS_CONTENT_LOADERS}
                </>
            ) : (
                <div className={styles.news}>
                    {news.length > 0 && !isNewsLoading ? (

                        filteredNews.length > 0 ? (
                            filteredNews
                                .map((item) => <Post key={item.id} {...item} />)
                        ) : (
                            <p className={styles.news__errorMessage}>Ничего не найдено 😔</p>
                        )
                    ) : (
                        <p className={styles.news__errorMessage}>Ошибка! Сервер недоступен 😔</p>
                    )}
                    {filteredNews.length > 0 && !isNewsLoading && (
                        <div className="paginationButtons">
                            <button onClick={onClickNextPage} disabled={!nextPageLink}>
                                Предыдущая
                            </button>
                            <button onClick={onClickPrevPage} disabled={!prevPageLink}>
                                Следующая
                            </button>
                        </div>
                    )}
                </div>
            )}
        </section>
    );
};
export default News;
