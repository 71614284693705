import React from 'react'
import styles from './index.module.scss';
import { useNavigate } from "react-router-dom";
const BackButton = () => {
    const navigate = useNavigate();
    const onBackButtonClick = () => {
        navigate(-1);
    }
    return (
        <button className={styles.backButton} onClick={onBackButtonClick}>
            <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 8L1.6 4L4 0L2.4 1.39876e-07L-3.49691e-07 4L2.4 8L4 8Z" fill="#292929" />
            </svg>
        </button>
    )
}

export default BackButton