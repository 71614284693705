import React from "react";
import {IPost} from "../../utils/types";
import styles from "./Post.module.scss";
import ReactHtmlParser from "react-html-parser";
import moment from 'moment';
import 'moment/locale/ru';
import {NavLink} from "react-router-dom";

moment.locale('ru');

const Post: React.FC<IPost> = ({id, header, date, text}) => {
    return (
        <article className={styles.post}>
            <time dateTime={date} title={moment(date).format("LLLL")} className={styles.post__date}>
                {moment(date).format("LL")}
            </time>
            <NavLink to={`/news/${id}`} className={styles.post__title}>
                {header}
            </NavLink>
            <div className="parsed">
                {ReactHtmlParser(text)}
            </div>
        </article>
    );
};
export default Post;
