import React from "react";
import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';

import "./App.scss";

import Main from "./components/Main/Main";
import News from "./components/News/News";
import Root from "./pages/Root";
import Error from "./pages/Error";
import MapsRegions from "./components/Maps/MapsRegions";
import RegionDetail from "./components/Maps/RegionDetail";
import NewsDetail from "./components/News/NewsDetail";
import Federation from "./components/Federation/Federation";
import Articles from "./components/Articles/Articles";
import ArticleDetail from "./components/Articles/ArticleDetail";
import Authors from "./components/Authors/Authors";
import Maps from "./components/Maps/Maps";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        errorElement: <Error/>,
        children: [
            {
                path: '/',
                element: <News/>,
                // element: <Main/>,
            },
            {
                path: '/news',
                element: <News/>,
            },
            {
                path: '/news/:postId',
                element: <NewsDetail/>,
            },
            {
                path: '/federation',
                element: <Federation/>,
            },
            {
                path: '/articles',
                element: <Articles/>,
            },
            {
                path: '/articles/:articleId',
                element: <ArticleDetail/>,
            },
            {
                path: '/authors',
                element: <Authors/>,
            },
            {
                path: '/maps',
                element: <Maps/>,
            },
            {
                // FIXME имена индексов
                path: '/maps/:regionIndex',
                element: <MapsRegions/>,
            },
            {
                path: '/maps/:regionIndex/:placeIndex',
                element: <RegionDetail/>,
            },

        ],
    },
]);


const App: React.FC = () => {
    return (
        <RouterProvider router={router}/>
    );
};

export default App;
