import React from 'react'
import styles from "./Maps.module.scss";
import {useParams} from "react-router-dom";
import {MAPS} from "../../utils/constants";
import BackButton from "../BackButton";
import Map from "./Map";
import {Helmet} from "react-helmet";


const RegionDetail: React.FC = () => {
    const {regionIndex, placeIndex} = useParams<Record<string, string | undefined>>();
    const items = MAPS[parseInt(regionIndex || "")]['places'][parseInt(placeIndex || "")]['maps'];
    const regionName = MAPS[parseInt(regionIndex || "")]['places'][parseInt(placeIndex || "")]['name'];

    return (
        <section className={styles.maps}>
            <Helmet>
                <meta name="description"
                      content={`Спортивные карты Саратовской области: ${regionName}`}/>
                <meta name="keywords"
                      content="o-saratov, карты, спортивные карты, спортивное ориентирование в Саратове, ориентирование в Саратове, ориентирование Саратов"/>
                <title>Карты {regionName} | o-saratov</title>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={`Карты ${regionName} | o-saratov`}/>
                <meta property="og:description"
                      content={`Спортивные карты Саратовской области: ${regionName}`}/>
                <meta property="og:site_name" content="o-saratov"/>
            </Helmet>
            <h2 className={styles.maps__title}>
                <div className={styles.mapsBackButton}>
                    <BackButton/>
                </div>
                {regionName}
            </h2>
            <ul className={styles.maps__list}>
                {
                    items.map((item, index) => (
                        <Map key={index} item={item}/>
                    ))
                }
            </ul>
        </section>

    )
}

export default RegionDetail
