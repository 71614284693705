import React from "react";
import {IArticle} from "../../utils/types";
import styles from "./Article.module.scss";
import ReactHtmlParser from "react-html-parser";
import moment from 'moment';
import 'moment/locale/ru';
import {NavLink} from "react-router-dom";

moment.locale('ru');

const Article: React.FC<IArticle> = ({header, date, text, source, author, id}) => {
    return (
        <article className={`${styles.article} ${styles.preview}`}>
            <time dateTime={date} title={moment(date).format("LLLL")}>
                {moment(date).format("LL")}
            </time>

            <h2 className={styles.article__title}>
                <NavLink to={`/articles/${id}`} className={styles.article__title}>
                    {header}
                </NavLink>
            </h2>
            <div className="parsed">
                {ReactHtmlParser(text)}
            </div>

            {source && (
                <span className={styles.source}>Источник: <mark>{source}</mark></span>
            )}
            {author && (
                <span className={styles.author}>Автор: <mark>{author}</mark></span>
            )}
            <NavLink to={`/articles/${id}`} className={styles.article__readMore}>
                Читать полностью
            </NavLink>
            <div className={styles.fade}></div>
        </article>
    );
};
export default Article;
